// import "core-js/core/array"
import { gsap, TweenMax, TimelineMax} from "gsap";
import ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";


ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

// Анимация карты на главной
export function mapAnimation() {
    const scrollControler = new ScrollMagic.Controller();
    
    let windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth; 
    let tween

    // Анимация для декстопа
    if(windowWidth > 1200) {
      tween = new gsap.timeline({smoothChildTiming: true})
      .to('.map__svg_right', 0.5, {scale: 1.3}, '-=0.6')
      .to('.map__svg_right', 0.5, {y:-500, x: 200}, '-=0.6')

      .set('.map__route',{opacity: 0, scale: 1.3, y:-155, x: 190}, "-=0.5")  
      
      .to('.map__head__text', 0.3, {opacity: 0, y: -250}, '-=0.4')
      .to('.mpk_map_text_row', 0.5, {y: -450}, '-=0.4')
      
      .fromTo('.map__route', 0.3, {opacity:0}, {opacity: 1,  overwrite: false}, '-=0.15')
      .fromTo('.mpk_map_annotations', 0.3, {opacity:0}, {opacity: 1,  overwrite: false}, '-=0.12')
      .fromTo('.map_road_live_on', 3, {strokeDashoffset: 2000}, {strokeDashoffset: 200,  overwrite: false}, '-=0.1')

      // build scene
    let scene = new ScrollMagic.Scene({
      triggerElement: ".mapTrigger",
      triggerHook: "onLeave",
      duration: 2500,
      // tweenChanges: true,
    })
      .setTween(tween)
      .setPin(".map__wrap")
      .addTo(scrollControler);
    }
    // Анимация для планшента 
    else if(windowWidth > 767) {
      tween = new gsap.timeline()
      .set('.map__svg', {x: 500}, "-=0.7")
      .set('.map_road_live', {x:-11, y: -56})
      .to('.map__svg_right', 0.5, {y:-500, x: 200}, '-=0.6')

      .set('.map__route',{opacity: 0, scale: 1, y:-263, x: 196}, "-=0.5")  
      
      .to('.map__head__text', 0.5, {opacity: 0, y: -250}, '-=0.4')
      .to('.mpk_map_text_row', 0.3, {y: -450}, '-=0.4')
      
      .fromTo('.map__route', 0.2, {opacity:0}, {opacity: 1,  overwrite: false}, '-=0.12')
      .fromTo('.mpk_map_annotations', 0.3, {opacity:0}, {opacity: 1,  overwrite: false}, '-=0.1')
      .fromTo('.map_road_live_on', 0.5, {strokeDashoffset: 2000}, {strokeDashoffset: 200,  overwrite: false}, '-=0.06')

      // build scene
    let scene = new ScrollMagic.Scene({
      triggerElement: ".mapTrigger",
      triggerHook: "onLeave",
      duration: 2200,
      // tweenChanges: true,
    })
      .setTween(tween)
      .setPin(".map__wrap")
      .addTo(scrollControler);

    } else {
    // Анимация для мобилки
    tween = new gsap.timeline()
    // .set('.map__svg_right', {scale: 0.3, x: 500}, '-=0.7')

    .to('.map__head__text', 0.3, {opacity: 0, y: -250}, '-=0.6')
    .fromTo('.mpk_map_text_row', {position: "static"}, {position:'absolute', width: 100+'%'}, "-=0.5")
    .to('.mpk_map_text_row', 0.3, {top: 0}, '-=0.5')
    .to('.map_text_block_header', 0.3, {y: -140}, '-=0.45')
    .to('.map_text_stats', 0.3, {y: -96}, '-=0.4')

    .to('.map__svg_right', 0.4, {scale: 0.6}, '-=0.5')
    .to('.map__svg_right', 0.4, {y:-500, x: 765}, '-=0.5')

    .set('.map__route',{opacity: 0, scale: 0.6, y:-155, x: 190}, "-=0.3")  
    
    .fromTo('.map__route', 0.2, {opacity:0}, {opacity: 1,  overwrite: false}, '-=0.12')
    .fromTo('.mpk_map_annotations', 0.3, {opacity:0}, { opacity: 1,  overwrite: false}, '-=0.1')
    .fromTo('.map_road_live_on', 0.5, {strokeDashoffset: 2000}, {strokeDashoffset: 200,  overwrite: false}, '-=0.06')
    
    // build scene
    let scene = new ScrollMagic.Scene({
      triggerElement: ".mapTrigger",
      triggerHook: "onLeave",
      duration: 900,
      // tweenChanges: true,
    })
      .setTween(tween)
      .setPin(".map__wrap")
      .addTo(scrollControler);
  }

    

    // Мигающие точки на главной
  if (document.querySelectorAll(".map__marker").length > 0) {
    const tl = new gsap.timeline()
      .fromTo(
        ".map__marker",
        1.5,
        {
          attr: {
            r: 3,
          },
        },
        {
          attr: {
            r: 15,
          },
        }
      )
      .fromTo(
        ".map__marker",
        1.5,
        {
          opacity: 1,
        },
        {
          opacity: 0,
        },
        "-=1"
      )
      .repeat(-1);
  }
  }
  // Анимация карты на главной END
  
//   function countUp($el) {
//     $({
//         Counter: 0
//     }).animate({
//         Counter: $el.attr('data-text')
//     }, {
//         duration: 2000,
//         easing: 'swing',
//         step: function () {
//             $el.text(Math.ceil(this.Counter));
//         }
//     });
// }