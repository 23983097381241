<template>
  <div>
    <VueSlickCarousel
      ref="carousel"
      v-bind="slickSettings"
      @afterChange="afterSlideChange"
    >
      <div v-for="slide in content" :key="slide.id" class="pap">
        <div class="iconsSlider__item">
          <img
            :src="
              require(`@/assets/img_new/mpkabel/icons_block/${slide.imgName}`)
            "
          />
          <span v-if="slide.name">{{ slide.name }}</span>
        </div>
      </div>
    </VueSlickCarousel>
    <div class="iconsSlider__arrows">
      <div
        :class="[firstSlide ? 'inActiveArrow' : '']"
        class="iconsSlider__arrows__prev"
        @click="showPrev"
      >
        <img :src="require('@/assets/img_new/iconsSlider/arrow.svg')" />
      </div>
      <div class="iconsSlider__arrows__count">
        <span>{{ currentSlide }}/{{ totalSlides }}</span>
      </div>
      <div
        class="iconsSlider__arrows__next"
        @click="showNext"
        :class="[lastSlide ? 'inActiveArrow' : '']"
      >
        <img :src="require('@/assets/img_new/iconsSlider/arrow.svg')" />
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
  name: 'IconsSlider',
  props: ["content"],
  data() {
    return {
      slickSettings: {
        dots: false,
        arrows: false,
        centerMode: true,
        infinite: false,
        swipeToSlide: true,
      },
      currentSlide: 1,
      totalSlides: this.content.length,
      firstSlide: true,
      lastSlide: false,
    };
  },
  components: { VueSlickCarousel },
  methods: {
    showNext() {
      if (this.lastSlide != true) {
        this.$refs.carousel.next();
        this.afterSlideChange();
      }
    },
    showPrev() {
      if (this.firstSlide != true) {
        this.$refs.carousel.prev();
        this.afterSlideChange();
      }
    },
    disableBtns() {
      if (this.currentSlide != 1) {
        this.firstSlide = false;
      }
      if (this.currentSlide < this.totalSlides) {
        this.lastSlide = false;
      }
      if (this.currentSlide == this.totalSlides) {
        this.lastSlide = true;
      }
      if (this.currentSlide == 1) {
        this.firstSlide = true;
      }
    },
    hideInactiveSlides() {
      let slides = document.querySelectorAll(".slick-slide");
      slides.forEach((slide) => {
        if (slide.ariaHidden) {
          slide.classList.add("inActiveSlide");
        } else {
          slide.classList.remove("inActiveSlide");
        }
      });
    },
    slideCount() {
      let slides = document.querySelectorAll(".slick-slide");
      let activeSlide = document.querySelector(".slick-active");
      let index = Array.prototype.indexOf.call(slides, activeSlide);

      this.currentSlide = index + 1;
    },
    afterSlideChange() {
      this.slideCount();
      this.hideInactiveSlides();
      this.disableBtns();
    },
  },
  mounted() {
    this.hideInactiveSlides();
    this.slideCount();
  },
};
</script>

<style lang="sass">
@import "@/assets/new_sass/IconSlider.sass"
</style>